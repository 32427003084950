import makeStyles from '@mui/styles/makeStyles';

interface IStyleProps {
  desktop: boolean;
  backgroundImage: string;
}

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '1rem',
    background: '#FFFCF8',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    // borderRadius: '8px',
    // marginLeft: '16px',
    // marginRight: '16px',
  },
  sourceImage: {
    backgroundImage: (props: IStyleProps) =>
      `url(${props.backgroundImage}?width=128)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '8rem',
    minWidth: '8rem',
    height: '10rem',
    border: 'solid 1px black',
    marginRight: '1rem',
  },
  button: {
    width: '9.25rem',
    height: '2.5rem',
    background: '#3276B5',
    borderRadius: '.25rem',
  },
  buttonWideContainer: {},
  buttonNarrowContainer: {
    marginBottom: '1rem',
  },
  contentCenter: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
  },
  contentTop: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '100%',
    color: '#393636',
  },
  metaDescription: {
    maxWidth: '38rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#676767',
  },
  lockedIcon: {
    marginRight: '.5rem',
  },
}));
