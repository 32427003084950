import { parseHtml } from '@demiplane-dev/ui-components/src/Compendium/utils/custom-html-parser';
import { Box, useMediaQuery } from '@mui/material';
import { ElementPurchaseOptions } from 'Components/ElementPurchaseOptions';
import { ElementToolTip } from 'Components/ElementToolTip';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { imgOpLoader } from '@demiplane-dev/ui-components/src/image-optimization-loader';
import { IEventElementDisplayDrawer } from '@demiplane-dev/types';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ElementToolTipDataPrimerVersion } from 'Graphql/types';
import { useUserEntitlementsQuery } from 'Graphql/user-entitlements.query.generated';
import { useRxJsEmitter } from 'Hooks/RxJsEmitter';
import { ManagedElementTooltip } from 'Components/ElementToolTip/ManagedElementTooltip';

interface IElementDisplayProps {
  primerVersion: ElementToolTipDataPrimerVersion;
  showElementImage: boolean;
  maxHeight?: string; // maxHeight in pixels
  onOverflow?: () => void;
  type: string;
  onTooltipLinkClick?: (
    nexusId: string,
    category: string,
    element: string
  ) => void;
  hideFooter?: boolean;
  forceEntitled?: boolean;
}

export const PrimerToolTipDisplay = ({
  primerVersion,
  maxHeight,
  onOverflow,
  showElementImage,
  type,
  onTooltipLinkClick,
  hideFooter,
  forceEntitled,
}: IElementDisplayProps) => {
  const theme = useTheme();
  const matchesWidth = useMediaQuery('(min-width:600px)');
  const containerRef = useRef<HTMLDivElement>(null);
  const [isEntitled, setIsEntitled] = useState(false);
  const { emit } = useRxJsEmitter();
  const nexusSlug = primerVersion.primer.nexus.slug;
  const nexusEarlyAccess = primerVersion.primer.nexus.early_access;
  const [themeConfig, setThemeConfig] = useState<any>();

  const classes = useStyles({
    maxHeight,
    desktop: matchesWidth,
    type,
    showElementImage,
    hideFooter,
  });

  const primerId = primerVersion.primer.id;

  useEffect(() => {
    if (
      !!primerVersion.primer.nexus &&
      !!primerVersion.primer.nexus.config_data
    ) {
      setThemeConfig(primerVersion.primer.nexus.config_data.theme);
    }
  }, [primerVersion]);

  const [entitlementResult] = useUserEntitlementsQuery({
    variables: {
      category: primerVersion.primer.category + '_display',
      ids: [primerId ?? ''],
    },
    pause: !primerId,
    requestPolicy: 'network-only',
  });

  const { data: entitlementData, fetching: entitlementDataLoading } =
    entitlementResult;

  useEffect(() => {
    let isMounted = true;

    if (!!containerRef.current) {
      if (isMounted) {
        if (
          !!onOverflow &&
          containerRef.current.clientHeight < containerRef.current.scrollHeight
        ) {
          onOverflow();
        }
      }
    }
    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  });

  useEffect(() => {
    if (nexusSlug === 'pathfinder2e') {
      setIsEntitled(true);
    } else if (
      !entitlementDataLoading &&
      !!entitlementData &&
      !!entitlementData?.slsUserEntitlements?.data &&
      entitlementData.slsUserEntitlements.data.length === 1 &&
      entitlementData.slsUserEntitlements.data[0].owned.userStatus
    ) {
      setIsEntitled(true);
    }

    if (forceEntitled) {
      setIsEntitled(true);
    }
  }, [
    entitlementDataLoading,
    nexusSlug,
    forceEntitled,
    nexusEarlyAccess,
    entitlementData,
  ]);

  const elementImage = primerVersion.element_image
    ? `${primerVersion.element_image}?width=400`
    : undefined;

  const longDescription = primerVersion.long_description;
  const shortDescription = primerVersion.short_description;

  const handleTooltipLinkClick = (
    nexusId: string,
    category: string,
    slug: string
  ) => {
    if (!!onTooltipLinkClick) {
      onTooltipLinkClick(nexusId, category, slug);
    } else {
      emit<IEventElementDisplayDrawer>('openElementDisplayDrawer', {
        nexusId,
        category,
        slug,
      });
    }
  };

  let finalLongDescriptionHtml = '';
  if (!!primerVersion.long_description) {
    finalLongDescriptionHtml = primerVersion.long_description;
  }

  return (
    <Box
      ref={containerRef}
      className={`${classes.elementDisplay} element-display-container-${type}`}
    >
      {!!showElementImage && elementImage && matchesWidth && (
        <Box
          className={`${classes.elementImageContainer} element-display-image-container element-display-image-container-${type}`}
        >
          <Image
            loader={imgOpLoader}
            src={elementImage}
            alt='element'
            layout='fill'
            objectFit='contain'
            className={`element-display-image element-display-image-${type}`}
          />
        </Box>
      )}
      {isEntitled ? (
        <>
          {type === 'tooltip' ? (
            <Box
              className={`main-holder ${type}`}
              sx={{
                background: !!themeConfig
                  ? themeConfig.palette.base.base3
                  : theme.palette.base.base3,
              }}
            >
              <Box className={`${classes.elementDetailsTooltip} inner-holder`}>
                {!!longDescription &&
                  parseHtml({
                    content: finalLongDescriptionHtml,
                    chunkId: primerVersion.id,
                    linkComponent: Link,
                    nexusId: primerVersion.primer.nexus_id,
                    onTooltipLinkClick: handleTooltipLinkClick,
                    tooltipComponent: ElementToolTip,
                    ManagedTooltip: ManagedElementTooltip,
                    nexusSlug: nexusSlug as string,
                  })}
              </Box>
            </Box>
          ) : (
            <Box className={`main-holder ${type}`}>
              <Box className={`${classes.elementDetails} inner-holder`}>
                {!!longDescription &&
                  parseHtml({
                    content: finalLongDescriptionHtml,
                    chunkId: primerVersion.id,
                    linkComponent: Link,
                    nexusId: primerVersion.primer.nexus_id,
                    onTooltipLinkClick: handleTooltipLinkClick,
                    tooltipComponent: ElementToolTip,
                    ManagedTooltip: ManagedElementTooltip,
                    nexusSlug: nexusSlug as string,
                  })}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          {nexusSlug === 'pathfinder2e' ? (
            <Box
              className={`main-holder ${type}`}
              sx={{
                background: !!themeConfig
                  ? themeConfig.palette.base.base3
                  : theme.palette.base.base3,
                p: 4,
              }}
            >
              <Box className={classes.unentitledContainer}>
                <Box className={classes.unentitledTextContainer}>
                  {!!longDescription && !shortDescription && (
                    <>
                      {parseHtml({
                        content: finalLongDescriptionHtml,
                        chunkId: primerVersion.id,
                        linkComponent: Link,
                        nexusId: primerVersion.primer.nexus_id,
                        onTooltipLinkClick: handleTooltipLinkClick,
                        tooltipComponent: ElementToolTip,
                        ManagedTooltip: ManagedElementTooltip,
                        nexusSlug: nexusSlug as string,
                      })}
                    </>
                  )}
                  {!!shortDescription && !longDescription && (
                    <>
                      <Typography
                        classes={{ body1: classes.unentitledText }}
                        className={classes.unentitledText}
                      >
                        {shortDescription}
                      </Typography>
                    </>
                  )}
                  {!longDescription &&
                    !shortDescription &&
                    !!primerVersion.source && (
                      <Box className={classes.defaultText}>
                        <Typography
                          classes={{ body1: classes.unentitledText }}
                          className={classes.unentitledText}
                        >
                          This {primerVersion.primer.category} is Included in
                          the
                          <Typography
                            classes={{ body1: classes.linkText }}
                            className={classes.linkText}
                          >
                            <Link
                              href={`/nexus/${primerVersion.primer.nexus.slug}/sources/${primerVersion.source.slug}`}
                            >
                              {primerVersion.source.name}
                            </Link>
                          </Typography>
                          <Typography
                            classes={{ body1: classes.unentitledText }}
                            className={classes.unentitledText}
                          >
                            <Link
                              href={`/nexus/${primerVersion.primer.nexus.slug}/sources`}
                            >
                              View All Sources
                            </Link>
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              {!entitlementDataLoading &&
                type !== 'drawer' &&
                primerVersion.source && (
                  <Box
                    className={`main-holder ${type}`}
                    sx={{
                      background: !!themeConfig
                        ? themeConfig.palette.base.base3
                        : theme.palette.base.base3,
                    }}
                  >
                    <ElementPurchaseOptions
                      entitled={isEntitled}
                      primarySourceSku={primerVersion.source.sku}
                      primarySourceSlug={primerVersion.source.slug}
                      primarySourceName={primerVersion.source.name}
                      nexusAssetSlug={primerVersion.primer.nexus.asset_slug}
                      nexusSlug={primerVersion.primer.nexus.slug}
                      nexusName={primerVersion.primer.nexus.name}
                      container={'tooltip-sidebar'}
                    />
                  </Box>
                )}
            </>
          )}
        </>
      )}

      {!!showElementImage && elementImage && !matchesWidth && (
        <Box
          className={`${classes.elementImageContainer} element-display-image-container element-display-image-container-${type}`}
        >
          <Image
            loader={imgOpLoader}
            src={elementImage}
            alt='element'
            layout='fill'
            objectFit='contain'
            className={`element-display-image element-display-image-${type}`}
          />
        </Box>
      )}
    </Box>
  );
};
