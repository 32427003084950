import * as Types from './types';

import { gql } from 'urql';
import { ElementElementDisplayVersionNexusLandingFieldsFragmentDoc } from './element-element-display-version-nexus-landing.fragment.generated';
export type ElementElementDisplayVersionNexusFieldsFragment = { __typename?: 'demiplane_nexus', id: string, name: string, footer_data?: any | null, config_data?: any | null, asset_slug: string, slug: string, call_to_action_data?: any | null, early_access: boolean, listing_data?: any | null, nexus_landings: Array<{ __typename?: 'demiplane_nexus_landing', html: string }> };

export const ElementElementDisplayVersionNexusFieldsFragmentDoc = gql`
    fragment ElementElementDisplayVersionNexusFields on demiplane_nexus {
  id
  name
  nexus_landings(where: {nexus_landing_state: {name: {_eq: "ACTIVE"}}}) {
    ...ElementElementDisplayVersionNexusLandingFields
  }
  footer_data
  config_data
  asset_slug
  slug
  call_to_action_data
  early_access
  listing_data
}
    ${ElementElementDisplayVersionNexusLandingFieldsFragmentDoc}`;