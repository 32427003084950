import * as Types from './types';

import { gql } from 'urql';
import { ElementSourceFieldsFragmentDoc } from './element-source-fragment.generated';
import { ElementElementDisplayFieldsFragmentDoc } from './element-element-display.fragment.generated';
export type ElementElementDisplayVersionFieldsFragment = { __typename?: 'demiplane_element_display_version', id: string, name: string, element_display_id: string, activation_unit?: string | null, activation_range?: string | null, max_activation_value?: number | null, activation_value?: number | null, element_display?: string | null, element_thumbnail?: string | null, game_type_name?: string | null, is_spoiler?: boolean | null, level?: number | null, organized_play?: string | null, primary_source_name?: string | null, short_description?: string | null, tag_title?: string | null, traits?: string | null, tag_background_color: string, long_description?: string | null, basic: boolean, element_image?: string | null, weapon_traits?: string | null, armor_traits?: string | null, weapon_group?: string | null, armor_group?: string | null, item_subcategory?: string | null, base_items?: string | null, creature_family?: string | null, is_legacy?: boolean | null, primary_source?: { __typename?: 'demiplane_source', id: string, slug: string, name: string, sku: string, source_state: { __typename?: 'demiplane_source_state', name: string } } | null, elementDisplayByElementDisplayId: { __typename?: 'demiplane_element_display', id: string, category: string, nexus_id: string, slug: string, sku?: string | null, nexus: { __typename?: 'demiplane_nexus', id: string, name: string, footer_data?: any | null, config_data?: any | null, asset_slug: string, slug: string, call_to_action_data?: any | null, early_access: boolean, listing_data?: any | null, nexus_landings: Array<{ __typename?: 'demiplane_nexus_landing', html: string }> }, element_display_sources: Array<{ __typename?: 'demiplane_element_display_source', source: { __typename?: 'demiplane_source', id: string, slug: string, name: string, sku: string, source_state: { __typename?: 'demiplane_source_state', name: string } } }> } };

export const ElementElementDisplayVersionFieldsFragmentDoc = gql`
    fragment ElementElementDisplayVersionFields on demiplane_element_display_version {
  id
  name
  element_display_id
  activation_unit
  activation_range
  max_activation_value
  activation_value
  element_display
  element_thumbnail
  game_type_name
  is_spoiler
  level
  organized_play
  primary_source_name
  short_description
  tag_title
  traits
  tag_background_color
  long_description
  basic
  element_image
  weapon_traits
  armor_traits
  weapon_group
  armor_group
  item_subcategory
  base_items
  creature_family
  is_legacy
  primary_source {
    ...ElementSourceFields
  }
  elementDisplayByElementDisplayId {
    ...ElementElementDisplayFields
  }
}
    ${ElementSourceFieldsFragmentDoc}
${ElementElementDisplayFieldsFragmentDoc}`;