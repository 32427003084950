import React, { useEffect, useRef, useState } from 'react';
import { parseHtml } from '@demiplane-dev/ui-components/src/Compendium/utils/custom-html-parser';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useStyles } from './styles';
import { IEventElementDisplayDrawer } from '@demiplane-dev/types';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { ElementDisplayHeader } from 'Components/ElementDisplayHeader';
import { ElementPurchaseOptions } from 'Components/ElementPurchaseOptions';
import { ElementToolTip } from 'Components/ElementToolTip';
import { ElementElementDisplayVersionFieldsFragment } from 'Graphql/element-element_display-version.fragment.generated';
import { useUserEntitlementsQuery } from 'Graphql/user-entitlements.query.generated';
import { useElementDisplay } from 'Hooks/ElementDisplay';
import { useRxJsEmitter } from 'Hooks/RxJsEmitter';
import { ElementDisplayProvider } from 'src/state/providers/ElementDisplayProvider';
import { NexusTheme } from '../../theme/nexusTheme';
import { IElementDisplayProps } from './ElementDisplay.types';
import { ManagedElementTooltip } from 'Components/ElementToolTip/ManagedElementTooltip';
import { imgOpLoader } from '@demiplane-dev/ui-components/src/image-optimization-loader';
import { useCurrentUser } from 'Hooks/CurrentUser';

const ElementDisplayBase = ({
  element,
  maxHeight,
  onOverflow,
  showElementImage,
  type,
  onTooltipLinkClick,
  hideFooter,
  lineClamp,
  NotEntiltedComponent,
}: IElementDisplayProps) => {
  const theme = useTheme();
  const [associatedElementDisplays, setAssociatedElementDisplays] = useState<
    React.ReactElement[] | null
  >();
  const [themeConfig, setThemeConfig] = useState<any>();
  const { getElementDisplayVersion, isElementDisplayEntitled } =
    useElementDisplay();
  const matchesWidth = useMediaQuery('(min-width:600px)');
  const containerRef = useRef<HTMLDivElement>(null);
  const [isEntitled, setIsEntitled] = useState(false);
  const { emit } = useRxJsEmitter();
  const nexusSlug = element.elementDisplayByElementDisplayId.nexus.slug;
  const { currentUser } = useCurrentUser();

  // load and grab the config.theme if it exists
  useEffect(() => {
    if (
      !!element?.elementDisplayByElementDisplayId?.nexus &&
      element.elementDisplayByElementDisplayId.nexus.config_data
    ) {
      const nexusConfig =
        element.elementDisplayByElementDisplayId.nexus.config_data;
      setThemeConfig(nexusConfig.theme);
    }
  }, [
    element,
    element?.elementDisplayByElementDisplayId,
    element?.elementDisplayByElementDisplayId?.nexus,
  ]);

  const classes = useStyles({
    maxHeight,
    desktop: matchesWidth,
    type,
    showElementImage,
    hideFooter,
    lineClamp,
  });

  const elementDisplayId = element.elementDisplayByElementDisplayId.id;

  const [entitlementResult] = useUserEntitlementsQuery({
    variables: {
      category: element.elementDisplayByElementDisplayId.category + '_display',
      ids: [elementDisplayId ?? ''],
    },
    pause: !elementDisplayId,
    requestPolicy: 'network-only',
  });

  const { data: entitlementData, fetching: entitlementDataLoading } =
    entitlementResult;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (!!containerRef.current) {
      if (isMounted) {
        if (
          !!onOverflow &&
          containerRef.current.clientHeight < containerRef.current.scrollHeight
        ) {
          onOverflow();
        }
      }
    }
    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  });

  useEffect(() => {
    const getIsEntitled = async () => {
      if (nexusSlug === 'pathfinder2e') {
        setIsEntitled(true);
      } else if (!entitlementDataLoading) {
        let entitled = await isElementDisplayEntitled(
          elementDisplayId,
          entitlementData?.slsUserEntitlements?.data[0],
          !currentUser
        );
        setIsEntitled(entitled);
      }

      setLoading(false);
    };
    getIsEntitled();
    setLoading(true);
  }, [
    elementDisplayId,
    entitlementData,
    entitlementDataLoading,
    isElementDisplayEntitled,
    nexusSlug,
    currentUser,
  ]);

  const elementImage = element.element_image
    ? `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}/${element.element_image}?width=400`
    : undefined;

  const longDescription = element.long_description;
  const shortDescription = element.short_description;

  const handleTooltipLinkClick = (
    nexusId: string,
    category: string,
    slug: string
  ) => {
    if (!!onTooltipLinkClick) {
      onTooltipLinkClick(nexusId, category, slug);
    } else {
      emit<IEventElementDisplayDrawer>('openElementDisplayDrawer', {
        nexusId,
        category,
        slug,
      });
    }
  };

  useEffect(() => {
    const getAssociatedElementDisplays = async () => {
      const elementDisplays: ElementElementDisplayVersionFieldsFragment[] = [];

      if (
        element.elementDisplayByElementDisplayId.nexus.slug ===
          'pathfinder2e' &&
        element.elementDisplayByElementDisplayId.category === 'item'
      ) {
        if (element?.item_subcategory === 'Base Weapons') {
          if (!!element?.weapon_traits) {
            for (const weaponTrait of element.weapon_traits.split(',')) {
              const parsedTrait = weaponTrait
                .replace(/( [A-Za-z0-9_^]+)$/g, '')
                .replace(' ', '-')
                .replace(/(-[0-9]+)$/g, '')
                .trim();
              const elementDisplayVersionResult =
                await getElementDisplayVersion(
                  parsedTrait,
                  'weapon-trait',
                  element.elementDisplayByElementDisplayId.nexus.slug
                );
              if (!!elementDisplayVersionResult) {
                elementDisplays.push(elementDisplayVersionResult);
              }
            }
          }

          if (!!element?.weapon_group) {
            const elementDisplayVersionResult = await getElementDisplayVersion(
              element.weapon_group.toLowerCase(),
              'critical-specialization-effect',
              element.elementDisplayByElementDisplayId.nexus.slug
            );

            if (!!elementDisplayVersionResult) {
              elementDisplays.push(elementDisplayVersionResult);
            }
          }
        }

        if (element?.item_subcategory === 'Base Armor') {
          if (!!element?.armor_traits) {
            for (const armorTrait of element.armor_traits.split(',')) {
              const parsedTrait = armorTrait
                .replace(/( [A-Za-z0-9_^]+)$/g, '')
                .replace(' ', '-')
                .trim();
              const elementDisplayVersionResult =
                await getElementDisplayVersion(
                  parsedTrait,
                  'armor-trait',
                  element.elementDisplayByElementDisplayId.nexus.slug
                );
              if (!!elementDisplayVersionResult) {
                elementDisplays.push(elementDisplayVersionResult);
              }
            }
          }
          if (!!element?.armor_group) {
            const elementDisplayVersionResult = await getElementDisplayVersion(
              element.armor_group.toLowerCase(),
              'armor-specialization-effect',
              element.elementDisplayByElementDisplayId.nexus.slug
            );

            if (!!elementDisplayVersionResult) {
              elementDisplays.push(elementDisplayVersionResult);
            }
          }
        }

        if (!!element?.base_items) {
          for (const baseItem of element.base_items.split(',')) {
            const elementDisplayVersionResult = await getElementDisplayVersion(
              baseItem,
              'item',
              element.elementDisplayByElementDisplayId.nexus.slug
            );
            if (!!elementDisplayVersionResult) {
              elementDisplays.push(elementDisplayVersionResult);
            }
          }
        }
      } else if (
        element.elementDisplayByElementDisplayId.nexus.slug ===
          'pathfinder2e' &&
        element.elementDisplayByElementDisplayId.category === 'creature'
      ) {
        if (!!element?.creature_family) {
          let creatureFamilySlug = element.creature_family.toLowerCase();
          if (creatureFamilySlug.includes('’-')) {
            creatureFamilySlug = creatureFamilySlug.replaceAll('’-', '-');
          }
          if (creatureFamilySlug.includes(', ')) {
            creatureFamilySlug = creatureFamilySlug.replaceAll(', ', '-');
          }
          if (creatureFamilySlug.includes(' ')) {
            creatureFamilySlug = creatureFamilySlug.replaceAll(' ', '-');
          }
          if (creatureFamilySlug.includes(',')) {
            creatureFamilySlug = creatureFamilySlug.replaceAll(',', '-');
          }
          const elementDisplayVersionResult = await getElementDisplayVersion(
            creatureFamilySlug,
            'creature-family',
            element.elementDisplayByElementDisplayId.nexus.slug
          );

          if (!!elementDisplayVersionResult) {
            elementDisplays.push(elementDisplayVersionResult);
          }
        }
      } else {
        return null;
      }
      const elementDisplayComponents = elementDisplays.map((elementDisplay) => {
        return (
          <Box key={`${element.id} - ${elementDisplay.id}`}>
            <Box className={classes.assocElementDisplayHeader}>
              <ElementDisplayHeader
                activationUnit={elementDisplay.activation_unit}
                tagBackgroundColor={elementDisplay.tag_background_color}
                elementThumbnail={elementDisplay.element_thumbnail}
                activationValue={elementDisplay.activation_value}
                activationRange={elementDisplay.activation_range}
                maxActivationValue={elementDisplay.max_activation_value}
                elementCategory={
                  elementDisplay.elementDisplayByElementDisplayId?.category
                }
                elementSlug={
                  elementDisplay.elementDisplayByElementDisplayId?.slug
                }
                nexusSlug={
                  elementDisplay.elementDisplayByElementDisplayId?.nexus?.slug
                }
                nexusConfigData={
                  elementDisplay.elementDisplayByElementDisplayId?.nexus
                    ?.config_data
                }
                traits={elementDisplay.traits}
                name={elementDisplay.name}
                tagTitle={elementDisplay.tag_title}
                isLegacy={elementDisplay.is_legacy ?? false}
                type={type}
              />
            </Box>
            <ElementDisplay
              element={elementDisplay}
              showElementImage={true}
              type={type}
            />
          </Box>
        );
      });
      setAssociatedElementDisplays(elementDisplayComponents);
    };

    getAssociatedElementDisplays();
  }, [
    classes.assocElementDisplayHeader,
    element.armor_group,
    element.armor_traits,
    element.base_items,
    element.creature_family,
    element.elementDisplayByElementDisplayId.category,
    element.elementDisplayByElementDisplayId.nexus.slug,
    element.id,
    element?.item_subcategory,
    element.weapon_group,
    element.weapon_traits,
    getElementDisplayVersion,
    type,
  ]);

  let finalElementDisplayHtml = '';
  if (!!element.element_display) {
    finalElementDisplayHtml = element.element_display;
  }

  let finalLongDescriptionHtml = '';
  if (!!element.long_description) {
    finalLongDescriptionHtml = element.long_description;
  }

  return (
    <Box
      ref={containerRef}
      className={`${classes.elementDisplay} element-display-container-${type}`}
    >
      {!!showElementImage && elementImage && matchesWidth && isEntitled && (
        <Box
          className={`${classes.elementImageContainer} element-display-image-container element-display-image-container-${type}`}
        >
          <Image
            loader={imgOpLoader}
            src={elementImage}
            alt='element'
            layout='fill'
            objectFit='contain'
            className={`element-display-image element-display-image-${type}`}
          />
        </Box>
      )}
      {isEntitled ? (
        <>
          {type === 'tooltip' ? (
            <Box
              className={`main-holder ${type}`}
              sx={{
                background: !!themeConfig
                  ? themeConfig.palette.base.base3
                  : theme.palette.base.base3,
              }}
            >
              <Box
                className={`${classes.elementDetailsTooltip} ${type}-inner-holder inner-holder`}
              >
                {!!element?.element_display &&
                  parseHtml({
                    content: finalElementDisplayHtml,
                    chunkId: element.id,
                    linkComponent: Link,
                    nexusId: element.elementDisplayByElementDisplayId.nexus_id,
                    onTooltipLinkClick: handleTooltipLinkClick,
                    tooltipComponent: ElementToolTip,
                    ManagedTooltip: ManagedElementTooltip,
                    nexusSlug: nexusSlug as string,
                  })}
              </Box>
              {nexusSlug !== 'marvelrpg' && (
                <Box className={classes.longDescriptionTooltip}>
                  {!!longDescription &&
                    parseHtml({
                      content: finalLongDescriptionHtml,
                      chunkId: element.id,
                      linkComponent: Link,
                      nexusId:
                        element.elementDisplayByElementDisplayId.nexus_id,
                      onTooltipLinkClick: handleTooltipLinkClick,
                      tooltipComponent: ElementToolTip,
                      ManagedTooltip: ManagedElementTooltip,
                      nexusSlug: nexusSlug as string,
                    })}
                </Box>
              )}
              {associatedElementDisplays}
            </Box>
          ) : (
            <Box className={`main-holder ${type}`}>
              <Box
                className={`${classes.elementDetails} ${type}-inner-holder inner-holder`}
              >
                {!!element?.element_display &&
                  parseHtml({
                    content: finalElementDisplayHtml,
                    chunkId: element.id,
                    linkComponent: Link,
                    nexusId: element.elementDisplayByElementDisplayId.nexus_id,
                    onTooltipLinkClick: handleTooltipLinkClick,
                    tooltipComponent: ElementToolTip,
                    ManagedTooltip: ManagedElementTooltip,
                    nexusSlug: nexusSlug as string,
                  })}
              </Box>
              {nexusSlug !== 'marvelrpg' && !!longDescription && (
                <Box className={classes.longDescription}>
                  {parseHtml({
                    content: finalLongDescriptionHtml,
                    chunkId: element.id,
                    linkComponent: Link,
                    nexusId: element.elementDisplayByElementDisplayId.nexus_id,
                    onTooltipLinkClick: handleTooltipLinkClick,
                    tooltipComponent: ElementToolTip,
                    ManagedTooltip: ManagedElementTooltip,
                    nexusSlug: nexusSlug as string,
                  })}
                </Box>
              )}
              {associatedElementDisplays}
            </Box>
          )}
        </>
      ) : (
        <>
          {nexusSlug === 'pathfinder2e' && (
            <Box
              className={`main-holder ${type}`}
              sx={{
                background: !!themeConfig
                  ? themeConfig.palette.base.base3
                  : theme.palette.base.base3,
                p: 4,
              }}
            >
              <Box className={classes.unentitledContainer}>
                <Box className={classes.unentitledTextContainer}>
                  {!!longDescription && !shortDescription && (
                    <>
                      {parseHtml({
                        content: finalLongDescriptionHtml,
                        chunkId: element.id,
                        linkComponent: Link,
                        nexusId:
                          element.elementDisplayByElementDisplayId.nexus_id,
                        onTooltipLinkClick: handleTooltipLinkClick,
                        tooltipComponent: ElementToolTip,
                        ManagedTooltip: ManagedElementTooltip,
                        nexusSlug: nexusSlug as string,
                      })}
                    </>
                  )}
                  {!!shortDescription && !longDescription && (
                    <>
                      <Typography
                        classes={{ body1: classes.unentitledText }}
                        className={classes.unentitledText}
                      >
                        {shortDescription}
                      </Typography>
                    </>
                  )}
                  {!longDescription &&
                    !shortDescription &&
                    !!element.primary_source && (
                      <Box className={classes.defaultText}>
                        <Typography
                          classes={{ body1: classes.unentitledText }}
                          className={classes.unentitledText}
                        >
                          This{' '}
                          {element.elementDisplayByElementDisplayId.category} is
                          Included in the
                          <Typography
                            classes={{ body1: classes.linkText }}
                            className={classes.linkText}
                          >
                            <Link
                              href={`/nexus/${element.elementDisplayByElementDisplayId.nexus.slug}/sources/${element.primary_source.slug}`}
                            >
                              {element.primary_source_name}
                            </Link>
                          </Typography>
                          <Typography
                            classes={{ body1: classes.unentitledText }}
                            className={classes.unentitledText}
                          >
                            <Link
                              href={`/nexus/${element.elementDisplayByElementDisplayId.nexus.slug}/sources`}
                            >
                              View All Sources
                            </Link>
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>
            </Box>
          )}
          {entitlementDataLoading || loading ? (
            <Box className='entitlement_loading' />
          ) : !element.primary_source ? (
            <></>
          ) : NotEntiltedComponent ? (
            <NotEntiltedComponent element={element} />
          ) : type !== 'drawer' ? (
            <Box
              className={`main-holder ${type}`}
              sx={{
                background: !!themeConfig
                  ? themeConfig.palette.base.base3
                  : theme.palette.base.base3,
              }}
            >
              <ThemeProvider theme={NexusTheme}>
                <ElementPurchaseOptions
                  entitled={isEntitled}
                  primarySourceSku={element.primary_source?.sku}
                  primarySourceSlug={element.primary_source.slug}
                  primarySourceName={element.primary_source.name}
                  nexusAssetSlug={
                    element.elementDisplayByElementDisplayId.nexus.asset_slug
                  }
                  nexusSlug={
                    element.elementDisplayByElementDisplayId.nexus.slug
                  }
                  nexusName={
                    element.elementDisplayByElementDisplayId.nexus.name
                  }
                  container={'tooltip-sidebar'}
                />
              </ThemeProvider>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}

      {!!showElementImage && elementImage && !matchesWidth && isEntitled && (
        <Box
          className={`${classes.elementImageContainer} element-display-image-container element-display-image-container-${type}`}
        >
          <Image
            loader={imgOpLoader}
            src={elementImage}
            alt='element'
            layout='fill'
            objectFit='contain'
            className={`element-display-image element-display-image-${type}`}
          />
        </Box>
      )}
    </Box>
  );
};

export const ElementDisplay = (props: IElementDisplayProps) => (
  <ElementDisplayProvider>
    <ElementDisplayBase {...props} />
  </ElementDisplayProvider>
);
