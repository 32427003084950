import * as Types from './types';

import { gql } from 'urql';
import { ElementElementDisplayVersionFieldsFragmentDoc } from './element-element_display-version.fragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ElementElementDisplayVersionQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  category: Types.Scalars['String']['input'];
  nexusSlug: Types.Scalars['String']['input'];
}>;


export type ElementElementDisplayVersionQuery = { __typename?: 'query_root', demiplane_element_display_version: Array<{ __typename?: 'demiplane_element_display_version', id: string, name: string, element_display_id: string, activation_unit?: string | null, activation_range?: string | null, max_activation_value?: number | null, activation_value?: number | null, element_display?: string | null, element_thumbnail?: string | null, game_type_name?: string | null, is_spoiler?: boolean | null, level?: number | null, organized_play?: string | null, primary_source_name?: string | null, short_description?: string | null, tag_title?: string | null, traits?: string | null, tag_background_color: string, long_description?: string | null, basic: boolean, element_image?: string | null, weapon_traits?: string | null, armor_traits?: string | null, weapon_group?: string | null, armor_group?: string | null, item_subcategory?: string | null, base_items?: string | null, creature_family?: string | null, is_legacy?: boolean | null, primary_source?: { __typename?: 'demiplane_source', id: string, slug: string, name: string, sku: string, source_state: { __typename?: 'demiplane_source_state', name: string } } | null, elementDisplayByElementDisplayId: { __typename?: 'demiplane_element_display', id: string, category: string, nexus_id: string, slug: string, sku?: string | null, nexus: { __typename?: 'demiplane_nexus', id: string, name: string, footer_data?: any | null, config_data?: any | null, asset_slug: string, slug: string, call_to_action_data?: any | null, early_access: boolean, listing_data?: any | null, nexus_landings: Array<{ __typename?: 'demiplane_nexus_landing', html: string }> }, element_display_sources: Array<{ __typename?: 'demiplane_element_display_source', source: { __typename?: 'demiplane_source', id: string, slug: string, name: string, sku: string, source_state: { __typename?: 'demiplane_source_state', name: string } } }> } }> };


export const ElementElementDisplayVersionDocument = gql`
    query elementElementDisplayVersion($slug: String!, $category: String!, $nexusSlug: String!) {
  demiplane_element_display_version(
    order_by: {version_number: desc}
    where: {elementDisplayByElementDisplayId: {slug: {_eq: $slug}, category: {_eq: $category}, nexus: {slug: {_eq: $nexusSlug}}}}
    limit: 1
  ) {
    ...ElementElementDisplayVersionFields
  }
}
    ${ElementElementDisplayVersionFieldsFragmentDoc}`;

export function useElementElementDisplayVersionQuery(options: Omit<Urql.UseQueryArgs<ElementElementDisplayVersionQueryVariables>, 'query'>) {
  return Urql.useQuery<ElementElementDisplayVersionQuery, ElementElementDisplayVersionQueryVariables>({ query: ElementElementDisplayVersionDocument, ...options });
};