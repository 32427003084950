import Tooltip from '@mui/material/Tooltip';
import parse, {
  HTMLReactParserOptions,
  domToReact,
  DOMNode,
} from 'html-react-parser';
import React, {
  ComponentType,
  JSXElementConstructor,
  ReactElement,
} from 'react';
import { validateProps } from './validate-props';
import { Box } from '@mui/material';
import Image from 'next/image';
Image.displayName = 'Image';

type ManagedTooltipProps = {
  children: ReactElement;
  nexusId: string;
  category: string;
  slug: string;
};
interface IParseHtml {
  nexusId: string;
  content: string;
  chunkId: string;
  linkComponent: React.ComponentType<any>;
  ManagedTooltip?: ComponentType<
    ManagedTooltipProps & {
      children: ReactElement<any, string | JSXElementConstructor<any>>;
    }
  >;
  tooltipComponent?: React.ComponentType<any>;
  onInternalLinkClick?: (slug: string) => void;
  onTooltipLinkClick?: (
    nexusId: string,
    category: string,
    element: string
  ) => void;
  disableLinks?: boolean;
  isToc?: boolean;
  nexusSlug?: string;
  sourceSlug?: string;
  linkNewTab?: boolean;
}

export const parseHtml = ({
  nexusId,
  content,
  linkComponent,
  tooltipComponent,
  onInternalLinkClick,
  onTooltipLinkClick,
  disableLinks,
  isToc,
  linkNewTab,
  ManagedTooltip,
}: IParseHtml) => {
  /**
   * I got some TS issues, that's why I came up with `domNode`.
   *
   * Related issues:
   * @see https://github.com/remarkablemark/html-react-parser/issues/221#issuecomment-771600574
   */

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        isToc &&
        disableLinks &&
        domNode.type === 'tag' &&
        domNode.name === 'a'
      ) {
        return (
          <span className='toc-link'>
            {domToReact(domNode.children as DOMNode[], options)}
          </span>
        );
      }

      if (domNode.type === 'tag' && domNode.name === 'href') {
        const validProps = validateProps(domNode.attribs, ['href']);

        if (!validProps) {
          return <>{'There was an error'}</>;
        }

        const Link = linkComponent;

        const externalRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

        if (disableLinks) {
          return (
            <span className='reader-link'>
              {domToReact(domNode.children as DOMNode[], options)}
            </span>
          );
        } else {
          if (
            externalRegex.test(domNode.attribs.href) ||
            domNode.attribs['href-type'] === 'image-link' ||
            !!linkNewTab
          ) {
            return (
              <a
                className='reader-link'
                style={{ cursor: 'pointer' }}
                target='_blank'
                href={
                  domNode.attribs['href-type'] === 'image-link'
                    ? `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${domNode.attribs.href}`
                    : domNode.attribs.href
                }
                rel='noopener noreferrer'
              >
                {domToReact(domNode.children as DOMNode[], options)}
              </a>
            );
          } else {
            if (
              externalRegex.test(domNode.attribs.href) ||
              domNode.attribs['href-type'] === 'image-link' ||
              !!linkNewTab
            ) {
              return (
                <a
                  className='reader-link'
                  style={{ cursor: 'pointer' }}
                  target='_blank'
                  href={
                    domNode.attribs['href-type'] === 'image-link'
                      ? `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT}${domNode.attribs.href}`
                      : domNode.attribs.href
                  }
                  rel='noopener noreferrer'
                >
                  {domToReact(domNode.children as DOMNode[], options)}
                </a>
              );
            } else {
              return (
                <Link
                  href={
                    domNode.attribs['href-type'] === 'image-link'
                      ? `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${domNode.attribs.href}`
                      : domNode.attribs.href
                  }
                  prefetch={false}
                  className='reader-link'
                  style={{ cursor: 'pointer' }}
                >
                  {domToReact(domNode.children as DOMNode[], options)}
                </Link>
              );
            }
          }
        }
      }

      if (domNode.type === 'tag' && domNode.name === 'tooltip') {
        const validProps = validateProps(domNode.attribs, [
          'nexus',
          'category',
          'element',
        ]);

        if (!validProps) {
          return <>{'There was an error'}</>;
        }

        if (!!tooltipComponent) {
          const Tip = tooltipComponent;

          if (!!ManagedTooltip) {
            return (
              <ManagedTooltip
                nexusId={nexusId}
                category={domNode.attribs.category}
                slug={domNode.attribs.element}
              >
                <a
                  style={{
                    borderBottom: '1px dotted #000',
                    textDecoration: 'none',
                  }}
                  className={`tooltip-link tooltip-link-${domNode.attribs.category}`}
                  // @ts-ignore
                  // eslint-disable-next-line react/no-unknown-property
                  nexus={domNode.attribs.nexus}
                  // eslint-disable-next-line react/no-unknown-property
                  category={domNode.attribs.category}
                  // eslint-disable-next-line react/no-unknown-property
                  slug={domNode.attribs.element}
                  onClick={
                    onTooltipLinkClick
                      ? () => {
                          onTooltipLinkClick(
                            nexusId,
                            domNode.attribs.category,
                            domNode.attribs.element
                          );
                        }
                      : undefined
                  }
                >
                  {domToReact(domNode.children as DOMNode[], options)}
                </a>
              </ManagedTooltip>
            );
          }

          if (!!onTooltipLinkClick) {
            return (
              <Tooltip
                title={
                  <Tip
                    nexusId={nexusId}
                    category={domNode.attribs.category}
                    slug={domNode.attribs.element}
                  />
                }
                classes={{
                  tooltip: `tooltip-container`,
                }}
                placement='left'
                componentsProps={{
                  popper: {
                    sx: {
                      zIndex: '1205 !important',
                    },
                  },
                  tooltip: {
                    sx: {
                      maxWidth: 'min(100vw, 900px)',
                      overflow: 'scroll',
                    },
                  },
                }}
              >
                <a
                  style={{
                    borderBottom: '1px dotted #000',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  className={`tooltip-link tooltip-link-${domNode.attribs.category}`}
                  // @ts-ignore
                  // eslint-disable-next-line react/no-unknown-property
                  nexus={domNode.attribs.nexus}
                  // eslint-disable-next-line react/no-unknown-property
                  category={domNode.attribs.category}
                  // eslint-disable-next-line react/no-unknown-property
                  slug={domNode.attribs.element}
                  onClick={() => {
                    onTooltipLinkClick(
                      nexusId,
                      domNode.attribs.category,
                      domNode.attribs.element
                    );
                  }}
                >
                  {domToReact(domNode.children as DOMNode[], options)}
                </a>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip
                title={
                  <Tip
                    nexusId={nexusId}
                    category={domNode.attribs.category}
                    slug={domNode.attribs.element}
                  />
                }
                classes={{
                  tooltip: `tooltip-container`,
                }}
                componentsProps={{
                  popper: {
                    sx: {
                      zIndex: '1205 !important',
                    },
                  },
                  tooltip: {
                    sx: {
                      maxWidth: 'min(100vw, 900px)',
                      overflow: 'scroll',
                    },
                  },
                }}
                placement='left'
                // open={domNode.attribs.element === 'chimera'}
              >
                <a
                  style={{
                    borderBottom: '1px dotted #000',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  className={`tooltip-link tooltip-link-${domNode.attribs.category}`}
                  // @ts-ignore
                  // eslint-disable-next-line react/no-unknown-property
                  nexus={domNode.attribs.nexus}
                  // eslint-disable-next-line react/no-unknown-property
                  category={domNode.attribs.category}
                  // eslint-disable-next-line react/no-unknown-property
                  slug={domNode.attribs.element}
                >
                  {domToReact(domNode.children as DOMNode[], options)}
                </a>
              </Tooltip>
            );
          }
        }
      }

      if (domNode.type === 'tag' && domNode.name === 'img') {
        const validProps = validateProps(domNode.attribs, ['src']);

        if (!validProps) {
          return <>{'There was an error'}</>;
        }

        const {
          src,
          alt,
          width,
          height,
          class: className,
          ...rest
        } = domNode.attribs;

        if (!!width && !!height) {
          delete rest.srcset;

          return (
            <Image
              src={src}
              alt={!!alt ? alt : ''}
              width={parseFloat(width)}
              height={parseFloat(height)}
              className={className}
              {...rest}
            />
          );
        } else {
          if (!!width) {
            delete rest.srcset;

            return (
              <Box
                sx={{
                  position: 'relative',
                  width: parseFloat(width),
                }}
              >
                <Image
                  src={src}
                  alt={!!alt ? alt : ''}
                  width={parseFloat(width)}
                  height={0}
                  style={{
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                  {...rest}
                />
              </Box>
            );
          } else if (!!height) {
            delete rest.srcset;

            return (
              <Box
                sx={{
                  position: 'relative',
                  height: parseFloat(height),
                }}
              >
                <Image
                  src={src}
                  alt={alt}
                  width={parseFloat(height)} // this looks dumb but the correct source from srcset seems to be by width.  so having 0 causes issues.  matching the height gets us at least that source from srcset
                  height={parseFloat(height)}
                  style={{
                    width: 'auto',
                    objectFit: 'cover',
                  }}
                  {...rest}
                />
              </Box>
            );
          }
        }
      }

      return false;
    },
  };

  return parse(content, options);
};
